* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  font-family: "Nunito", sans-serif;
  font-family: "Nunito Sans", sans-serif;
  /* font-family: "Roboto", sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.Toastify__toast--default {
  background-color: #101828;
  color: white;
  font-size: 13px;
}

.Toastify__close-button.Toastify__close-button--light {
  color: white;
}

.p-dropdown {
  /* border: 1px solid red; */
}

.p-dropdown-label {
  padding: 10px 12px;
}

.p-dropdown-panel {
  /* color: #007bff; */
}

.p-dropdown-items-wrapper {
  /* border: 1px solid #007bff; */
}

.p-dropdown-items {
  padding: 10px 0px;
}

.p-dropdown-item {
  padding: 10px 20px;
}
